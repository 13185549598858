import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import './MyOrderTab.style';

/** @namespace myApp/Component/MyOrderTab/Component */
export class MyOrderTabComponent extends PureComponent {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        orderCount: PropTypes.number.isRequired
    };

    tabMap = [
        this.renderOrderButton.bind(this)
    ];

    renderOrderButton() {
        const { activeTab, orderCount } = this.props;
        return (
            <li block="ProductTab" elem="Item" mods={ { isActive: activeTab === 'order' } }>
                <Link block="ProductTab-Button" to="/sales/order/history">
                    { `${__('Orders')} (${orderCount}) ` }
                </Link>
            </li>
        );
    }

    render() {
        return (
            <div block="MyOrderTab">
                <ul block="ProductTabs">
                    { this.tabMap.map((item) => item()) }
                </ul>
            </div>
        );
    }
}

export default MyOrderTabComponent;
