import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import MyOrderTab from './MyOrderTab.component';

/** @namespace myApp/Component/MyOrderTab/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    orderList: state.OrderReducer.orderList
});

/** @namespace myApp/Component/MyOrderTab/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // TODO;
});

/** @namespace myApp/Component/MyOrderTab/Container */
export class MyOrderTabContainer extends PureComponent {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        orderList: PropTypes.objectOf.isRequired
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        const { orderList, activeTab } = this.props;

        return {
            activeTab,
            orderCount: orderList && orderList.items ? orderList.items.length : 0
        };
    }

    render() {
        return (
            <MyOrderTab
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrderTabContainer));
